import React, { useEffect, useMemo } from 'react';
import _ from 'underscore';
import { Overflow, TabList } from '@fluentui/react-components';
import { SideNavigationProps, SideNavigationItemProps, SideNavigationItemSelectionMode } from './types';
import { SideNavigationPopoverMenu } from './side-navigation-popover';
import { SiteNavigationGroupItems } from './side-navigation-grouped-items';
import { SiteNavigationUnGroupedItems } from './side-navigation-ungrouped-items';
import {
  defaultConfig,
  getConfig,
  getItems,
} from './utilities';
import { SideNavigationModes } from './side-navigation-mode';

export const SideNavigation: React.FC<SideNavigationProps> = ({
  items = [],
  className,
  mode = SideNavigationModes.Detailed,
  reserveSelectedTabSpace,
  config: overrideConfig = defaultConfig,
}) => {
  const flattenedGroupedItems = useMemo(() => _.flatten(items), [items]);
  const { groupedItems, trailingItems: trailingNavItems } = useMemo(() => getItems(items), [items]);
  const config = useMemo(() => getConfig(overrideConfig), [overrideConfig]);
  const {
    getMandatoryFilter: mandatoryFilterConfig,
    getSelectedNavigationItemId,
    minimumVisibleCount: defaultMinimumVisibleCount,
  } = config;
  const selectedNavigationItem = useMemo(
    () => getSelectedNavigationItemId(flattenedGroupedItems),
    [flattenedGroupedItems, getSelectedNavigationItemId],
  );
  const [internalSelectedTabId, setInternalSelectedTabId] = React.useState<string>(selectedNavigationItem);

  useEffect(() => setInternalSelectedTabId(selectedNavigationItem), [selectedNavigationItem]);

  const minimumVisibleCount = React.useMemo(() => {
    const mandatoryItems = flattenedGroupedItems.filter(mandatoryFilterConfig);
    const itemsCount = mandatoryItems ? Math.max(mandatoryItems.length, defaultMinimumVisibleCount) : 0;
    return itemsCount;
  }, [flattenedGroupedItems, mandatoryFilterConfig, defaultMinimumVisibleCount]);

  const onTabSelect = (tabId: any) => {
    setInternalSelectedTabId(tabId);
  };

  const selectedTabId = React.useMemo(() => (
    config.selectionMode === SideNavigationItemSelectionMode.ItemId
      ? selectedNavigationItem
      : internalSelectedTabId
  ), [config.selectionMode, selectedNavigationItem, internalSelectedTabId]);

  return (
    <Overflow minimumVisible={minimumVisibleCount} overflowAxis="vertical">
      <TabList
        vertical
        selectedValue={selectedTabId}
        onTabSelect={(e, d) => onTabSelect(d.value)}
        appearance="subtle"
        reserveSelectedTabSpace={reserveSelectedTabSpace}
      >
        <SiteNavigationGroupItems
          items={groupedItems}
          selectedTabId={selectedTabId}
          className={className}
          mode={mode}
          config={config}
          onTabSelect={onTabSelect}
        />
        <SideNavigationPopoverMenu
          onTabSelect={onTabSelect}
          items={([] as SideNavigationItemProps[]).concat(...groupedItems)}
        />
        <SiteNavigationUnGroupedItems
          items={trailingNavItems}
          selectedTabId={selectedTabId}
          className={className}
          mode={mode}
          config={config}
          onTabSelect={onTabSelect}
        />
      </TabList>
    </Overflow>
  );
};
