import { makeStyles, mergeClasses } from '@griffel/react';
export const breadcrumbDividerClassNames = {
    root: 'fui-BreadcrumbDivider'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        display: 'flex'
    }
});
/**
 * Apply styling to the BreadcrumbDivider slots based on the state
 */ export const useBreadcrumbDividerStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(breadcrumbDividerClassNames.root, styles.root, state.root.className);
    return state;
};
