import React from 'react';
import _ from 'underscore';
import {
  mergeClasses,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  MenuList,
  MenuItem,
  Label,
  Button,
} from '@fluentui/react-components';

import { Pin20Filled, Pin20Regular, bundleIcon } from '@fluentui/react-icons';
import {
  SideNavigationItemProps,
  SideNavigationSecondaryItemProps,
  SideNavigationConfigProps,
} from './types';
import { SideNavigationModes } from './side-navigation-mode';
import { useSiteNavigationItemPopoverStyles } from './styles';
import { defaultConfig } from './utilities';
import { SideNavigationItemTab } from './side-navigation-item-tab';

export const PinIcon = bundleIcon(Pin20Filled, Pin20Regular);

type SideNavigationItemPopoverProps = {
  item: SideNavigationItemProps,
  // eslint-disable-next-line no-unused-vars
  onTabSelect: (selectedTabId: string) => void,
  className: string,
  mode: SideNavigationModes,
  config: SideNavigationConfigProps
};

export const SideNavigationItemPopover = (props: SideNavigationItemPopoverProps) => {
  const {
    item,
    onTabSelect,
    className,
    mode,
    config,
  } = props;
  const { secondaryNavItems = [], id, onPin } = item;
  const [open, setOpen] = React.useState(false);
  const styles = useSiteNavigationItemPopoverStyles();
  const {
    isTemporarySelected = defaultConfig.isTemporarySelected,
    popOverSettings,
  } = config;
  const temporarySelected = isTemporarySelected(item);
  const {
    popOverMouseLeaveDelay,
    withArrow,
    openOnHover,
  } = _.defaults(popOverSettings, defaultConfig.popOverSettings);

  const onItemClick = (primaryitem: SideNavigationItemProps, secondaryItem: SideNavigationSecondaryItemProps) => {
    onTabSelect(primaryitem.id);
    secondaryItem.onClick?.();
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      openOnHover={openOnHover}
      withArrow={withArrow}
      mouseLeaveDelay={popOverMouseLeaveDelay}
      positioning="after"
      onOpenChange={(e, data) => {
        setOpen(data.open || false);
      }}
    >
      <PopoverTrigger disableButtonEnhancement>
        <SideNavigationItemTab
          item={item}
          mode={mode}
          className={className}
          config={config}
          onClick={() => { setOpen(!open); item.onClick?.(); }}
        />
      </PopoverTrigger>
      <PopoverSurface className={styles.popOverContainer}>
        <div className={styles.popOverTitleContainer}>
          <Label className={styles.popOverTitle}>{item.text}</Label>
          {
            temporarySelected
            && onPin
            && (
            <Button
              icon={<PinIcon />}
              onClick={() => onPin(item)}
              className={mergeClasses('side-navigation-sub-menu-pin-button', styles.popOverPinButton)}
              appearance="transparent"
            />
            )
          }
        </div>
        { secondaryNavItems.length > 0 && (
        <MenuList className={mergeClasses('side-navigation-sub-menu-items', styles.menu)}>
          {secondaryNavItems.map((secondaryItem) => (
            <MenuItem
              key={`${id}_${secondaryItem.text}`}
              className={mergeClasses('side-navigation-sub-menu-item', styles.menuButton)}
              onClick={() => onItemClick(item, secondaryItem)}
            >
              {secondaryItem.text}
            </MenuItem>
          ))}
        </MenuList>
        )}
      </PopoverSurface>
    </Popover>
  );
};
