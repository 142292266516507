import React from 'react';
import { mergeClasses } from '@fluentui/react-components';
import { SideNavigationItemProps, SideNavigationUnGroupedItemsProps } from './types';
import { SideNavigationGroupDivider } from './side-navigation-divider';
import { SideNavigationItem } from './side-navigation-item';

export const SiteNavigationUnGroupedItems : React.FC<SideNavigationUnGroupedItemsProps> = ({
  items: trailingNavItems,
  selectedTabId,
  className,
  onTabSelect,
  mode,
  config,
}) => (
  <>
    {
      trailingNavItems.map((navItem: SideNavigationItemProps, index) => (
        <div
          key={`nav-trailing-item-${navItem.text}`}
          className={mergeClasses('side-nav-item-container')}
        >
          <SideNavigationItem
            key={navItem.id}
            item={navItem}
            groupId={`trailling_${index}`}
            className={className}
            onTabSelect={onTabSelect}
            selectedTabId={selectedTabId}
            mode={mode}
            config={config}
          />
          {
            index < trailingNavItems.length - 1 && (
            <SideNavigationGroupDivider
              key={`nav-trailing-divider-${navItem.text}`}
              groupId={`trailing_${index}`}
            />
            )
}
        </div>
      ))
}
  </>
);
