import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const subtitle2StrongerClassNames = {
    root: 'fui-Subtitle2Stronger'
};
/**
 * Styles for the root slot
 */ export const useSubtitle2StrongerStyles = makeStyles({
    root: typographyStyles.subtitle2Stronger
});
