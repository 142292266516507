import React, { forwardRef } from 'react';
import {
  mergeClasses,
  makeStyles,
  OverflowItem,
} from '@fluentui/react-components';

import {
  SideNavItemProps,
} from './types';
import { SideNavigationItemPopover } from './side-navigation-item-popover';
// eslint-disable-next-line import/no-cycle
import { SideNavigationAccordion } from './side-navigation-item-accordion';
import { defaultConfig } from './utilities';
import { SideNavigationItemTab } from './side-navigation-item-tab';

const useMenuStyles = makeStyles({
  tab: {
    '& span.side-navigation-item-text': {
      lineHeight: '14px',
      fontSize: '10',
    },
  },
});

const NavigationItem = forwardRef<HTMLButtonElement, SideNavItemProps>(({
  item,
  onTabSelect,
  mode,
  className,
  config,
}, ref) => {
  const { secondaryNavItems } = item;
  const styles = useMenuStyles();
  const subMenuClassName = mergeClasses('side-navigation-item-button', 'side-navigation-item-with-submenu', styles.tab, className);

  if (secondaryNavItems) {
    return (
      <SideNavigationItemPopover
        item={item}
        config={config}
        onTabSelect={onTabSelect}
        className={subMenuClassName}
        mode={mode}
      />
    );
  }

  return (
    <SideNavigationItemTab
      item={item}
      mode={mode}
      className={mergeClasses('side-navigation-item-button', styles.tab, className)}
      config={config}
      ref={ref}
    />
  );
});

export const SideNavigationItem: React.FC<SideNavItemProps> = (sideNavItem) => {
  const {
    item,
    groupId,
    selectedTabId,
    config,
    className,
    mode,
    onTabSelect,
  } = sideNavItem;

  const { getPriority = defaultConfig.getPriority } = config;
  const priority = getPriority(item, selectedTabId);

  const { secondaryNavItems, isAccordion, isCollapsed = false } = item;
  const hasAccordion = isAccordion && secondaryNavItems;
  const styles = useMenuStyles();
  const subMenuClassName = mergeClasses('side-navigation-item-button', 'side-navigation-item-with-submenu', styles.tab, className);

  if (hasAccordion) {
    return (
      <SideNavigationAccordion
        item={item}
        isCollapsed={isCollapsed}
        secondaryNavItems={secondaryNavItems}
        config={config}
        mode={mode}
        className={subMenuClassName}
        onTabSelect={onTabSelect}
        selectedTabId={selectedTabId}
        groupId={groupId}
      />
    );
  }

  return (
    <OverflowItem
      key={`overflow_${item.id}`}
      id={item.id}
      groupId={groupId}
      priority={priority}
    >
      <div>
        <NavigationItem {...sideNavItem} />
      </div>
    </OverflowItem>
  );
};
