import React from 'react';
import {
  makeStyles, mergeClasses, useIsOverflowGroupVisible, Divider,
} from '@fluentui/react-components';
import { SideNavigationDividerProps } from './types';

const useStyles = makeStyles({
  container: {
    flexGrow: 0,
    height: '9px',
  },
});

export const SideNavigationGroupDivider : React.FC<SideNavigationDividerProps> = ({
  groupId,
  className,
}) => {
  const classes = useStyles();
  const isGroupVisible = useIsOverflowGroupVisible(groupId);

  if (isGroupVisible === 'hidden') {
    return null;
  }

  return (
    <Divider
      inset
      className={mergeClasses(classes.container, className)}
    />
  );
};
