/* eslint-disable react/require-default-props */
import React from 'react';
import {
  makeStyles,
  mergeClasses,
  tokens,
  useOverflowMenu,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Button,
  useIsOverflowItemVisible,
  MenuItem,
  MenuList,
  Label,
} from '@fluentui/react-components';

import {
  bundleIcon,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
} from '@fluentui/react-icons';
import type { ARIAButtonElement } from '@fluentui/react-aria';

import { SideNavigationItemProps } from './types';
import { SideNavigationGroupDivider } from './side-navigation-divider';

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

const useOverflowMenuStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: 'center',
  },
  container: {
    textAlign: 'center',
  },
});

type OverflowMenuProps = {
  // eslint-disable-next-line no-unused-vars
  onTabSelect: (tabId: string) => void,
  items: Array<SideNavigationItemProps>
};

type Tab = {
  id: string;
  text: string;
  icon?: React.FC<{}>;
};

type OverflowMenuItemProps = {
  tab: Tab;
  onClick: React.MouseEventHandler<ARIAButtonElement<'div'>>;
  className?: string
};

const OverflowMenuItem = (props: OverflowMenuItemProps) => {
  const { tab, onClick, className } = props;
  const isVisible = useIsOverflowItemVisible(tab.id);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem key={tab.id} onClick={onClick} className={className}>
      {tab.text}
    </MenuItem>
  );
};

const renderPopoverMenu = (
  item: SideNavigationItemProps,
  // eslint-disable-next-line no-unused-vars
  onItemClick: (i: SideNavigationItemProps) => void,
  className: string,
) => {
  const { isAccordion, secondaryNavItems } = item;
  const hasAccordion = isAccordion && secondaryNavItems;
  if (hasAccordion) {
    return (
      <div key={item.id}>
        <Label>{item.text}</Label>
        {
          secondaryNavItems?.map((secondaryitem) => (
            <OverflowMenuItem
              key={secondaryitem.id}
              tab={secondaryitem}
              className={mergeClasses('side-navigation-more-item', className)}
              onClick={() => onItemClick(secondaryitem)}
            />
          ))
        }
      </div>
    );
  }

  return (
    <OverflowMenuItem
      key={item.id}
      tab={item}
      className={mergeClasses('side-navigation-more-item', className)}
      onClick={() => onItemClick(item)}
    />
  );
};

export const SideNavigationPopoverMenu = (props: OverflowMenuProps) => {
  const { onTabSelect, items } = props;
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();
  const [open, setOpen] = React.useState(false);

  const styles = useOverflowMenuStyles();

  const onItemClick = (item: SideNavigationItemProps) => {
    onTabSelect(item.id);
    item.onClick?.();
    setOpen(false);
  };

  if (!isOverflowing) {
    return null;
  }

  return (
    <Popover
      open={open}
      withArrow
      positioning="after"
      onOpenChange={(e, data) => {
        setOpen(data.open || false);
      }}
    >
      <PopoverTrigger disableButtonEnhancement>
        <div className={styles.container}>
          <Button
            appearance="transparent"
            className={mergeClasses('side-navigation-more-button', styles.menuButton)}
            ref={ref}
            icon={<MoreHorizontal />}
            aria-label={`${overflowCount} more tabs`}
            role="tab"
          />
          <SideNavigationGroupDivider
            key="nav-trailing-divider"
            groupId="trailing"
          />
        </div>
      </PopoverTrigger>
      <PopoverSurface>
        <MenuList className={mergeClasses('side-navigation-more-items', styles.menu)}>
          {
            items.map((item) => (renderPopoverMenu(item, onItemClick, styles.menu)))
          }
        </MenuList>
      </PopoverSurface>
    </Popover>
  );
};
