/* istanbul ignore file */
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useSiteNavigationItemAccordionStyles = makeStyles({
  button: {
    ...shorthands.border('none'),
    backgroundColor: tokens.colorSubtleBackground,
    fontWeight: tokens.fontWeightRegular,
    width: '-webkit-fill-available',
    justifyContent: 'start',
  },
});

export const useSiteNavigationItemPopoverStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: 'flex-start',
    ...shorthands.padding('6px', '10px', '6px', '10px'),
  },
  popOverContainer: {
    ...shorthands.padding('12px'),
  },
  popOverTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('0px', '0px', '0px', '12px'),
  },
  popOverTitle: {
    fontWeight: 600,
    fontSize: '14',
    lineHeight: '20px',
  },
  popOverPinButton: {},
});
