import React from 'react';
import { mergeClasses } from '@fluentui/react-components';
import { SideNavigationItemProps, SideNavigationGroupedItemsProps } from './types';
import { SideNavigationGroupDivider } from './side-navigation-divider';
import { SideNavigationItem } from './side-navigation-item';

export const SiteNavigationGroupItems : React.FC<SideNavigationGroupedItemsProps> = ({
  items,
  selectedTabId,
  className,
  onTabSelect,
  mode,
  config,
}) => (
  <>
    {
      items.map((navItem: Array<SideNavigationItemProps>, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`nav-item-${index}`}
          className={mergeClasses('side-nav-item-container')}
        >
          {navItem.map((item) => (
            <SideNavigationItem
              key={item.id}
              item={item}
              groupId={`${index}`}
              selectedTabId={selectedTabId}
              className={className}
              onTabSelect={onTabSelect}
              mode={mode}
              config={config}
            />
          ))}
          {
            index < items.length - 1 && (
            <SideNavigationGroupDivider
              // eslint-disable-next-line react/no-array-index-key
              key={`nav-divider-${index}`}
              groupId={`${index}`}
            />
            )
          }
        </div>
      ))
    }
  </>
);
