import { makeStyles, mergeClasses } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
import { menuItemClassNames } from '../MenuItem/useMenuItemStyles.styles';
export const menuSplitGroupClassNames = {
    root: 'fui-MenuSplitGroup'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        display: 'flex',
        [`& > .${menuItemClassNames.root}:nth-of-type(1)`]: {
            flexGrow: 1
        },
        [`& > .${menuItemClassNames.root}:nth-of-type(2)`]: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            paddingLeft: 0,
            '::before': {
                content: '""',
                width: tokens.strokeWidthThin,
                height: '24px',
                backgroundColor: tokens.colorNeutralStroke1
            }
        }
    }
});
/**
 * Apply styling to the MenuSplitGroup slots based on the state
 */ export const useMenuSplitGroupStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(menuSplitGroupClassNames.root, styles.root, state.root.className);
    return state;
};
