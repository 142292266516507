import { makeResetStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const drawerHeaderNavigationClassNames = {
    root: 'fui-DrawerHeaderNavigation'
};
/**
 * Styles for the root slot
 */ const useStyles = makeResetStyles({
    ...shorthands.margin(`calc(${tokens.spacingVerticalS} * -1)`, `calc(${tokens.spacingHorizontalL} * -1)`)
});
/**
 * Apply styling to the DrawerHeaderNavigation slots based on the state
 */ export const useDrawerHeaderNavigationStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(drawerHeaderNavigationClassNames.root, styles, state.root.className);
    return state;
};
