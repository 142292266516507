import { shorthands, mergeClasses, makeStyles } from '@griffel/react';
import { useBadgeStyles_unstable } from '../Badge/useBadgeStyles.styles';
export const counterBadgeClassNames = {
    root: 'fui-CounterBadge',
    icon: 'fui-CounterBadge__icon'
};
const useStyles = makeStyles({
    dot: {
        minWidth: 'auto',
        width: '6px',
        height: '6px',
        ...shorthands.padding('0')
    },
    hide: {
        display: 'none'
    }
});
/**
 * Applies style classnames to slots
 */ export const useCounterBadgeStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(counterBadgeClassNames.root, state.dot && styles.dot, !state.root.children && !state.dot && styles.hide, state.root.className);
    if (state.icon) {
        state.icon.className = mergeClasses(counterBadgeClassNames.icon, state.icon.className);
    }
    return useBadgeStyles_unstable(state);
};
