import _ from 'underscore';
import { mergeClasses } from '@fluentui/react-components';
import { SideNavigationItemProps, SideNavigationConfigProps, SideNavigationItemSelectionMode } from './types';

// eslint-disable-next-line no-unused-vars, no-shadow
const enum VisibilityPriority {
  // eslint-disable-next-line no-unused-vars
  Low = 1,
  // eslint-disable-next-line no-unused-vars
  High = 2,
}

export const getItems = (grpItems: Array<Array<SideNavigationItemProps>>) => {
  const trailingItems : Array<SideNavigationItemProps> = [];
  const groupedItems = _.filter(grpItems.map((items) => {
    items
      .filter((item) => item.isTrailing)
      .map((item) => trailingItems.push(item));
    return _.reject(items, (item) => !!item.isTrailing);
  }), (grpitem) => grpitem.length > 0);

  return { groupedItems, trailingItems };
};

const getItem = (item: any) => _.defaults(item, {
  isSelected: false, isPermanent: false, isPrimary: false, isTrailing: false,
});

export const defaultConfig = {
  getPriority: (item: any, selectedTabId: string) => {
    const resolvedItem = getItem(item);
    return (
      resolvedItem.id === selectedTabId
      || resolvedItem.isSelected)
      || (resolvedItem.isPermanent)
      || (resolvedItem.isPrimary)
      || (resolvedItem.isTrailing)
      ? VisibilityPriority.High
      : VisibilityPriority.Low;
  },
  getMandatoryFilter: (item: any) => {
    const resolvedItem = getItem(item);
    return (resolvedItem.isSelected) || (resolvedItem.isPermanent) || (resolvedItem.isPrimary) || (resolvedItem.isTrailing);
  },
  isTemporarySelected: (item: any) => {
    const resolvedItem = getItem(item);
    return resolvedItem.isTemporary && resolvedItem.isSelected;
  },
  // eslint-disable-next-line no-unused-vars
  getAccessoryItem: (_item: any) => (undefined),
  getSelectedNavigationItemId: (items: Array<SideNavigationItemProps>) => {
    const findSelectedNavigationItem = items.find((item) => (!!item.isSelected));
    const primaryNavigationItem = items.find((item) => (!!item.isPrimary)) || { id: '' };
    return findSelectedNavigationItem ? findSelectedNavigationItem.id : primaryNavigationItem.id;
  },
  popOverSettings: {
    popOverMouseLeaveDelay: 50, // in ms
    withArrow: true,
    openOnHover: true,
  },
  selectionMode: SideNavigationItemSelectionMode.State,
  minimumVisibleCount: 3,
};

export const getConfig = (overrideConfig: SideNavigationConfigProps) => _.defaults(overrideConfig, defaultConfig);

export const getTabClassNames = (item: SideNavigationItemProps, temporarySelected: boolean, className: string) => {
  let resolvedClassName;
  if (temporarySelected) {
    resolvedClassName = 'nav-item-unpinned-selected';
  } else if (item && item.isPrimary) {
    resolvedClassName = 'nav-item-primary';
  }

  return resolvedClassName ? mergeClasses(className, resolvedClassName) : className;
};
