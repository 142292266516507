import React from 'react';
import { Button, mergeClasses, OverflowItem } from '@fluentui/react-components';
import { ChevronRight20Regular, ChevronDown20Regular } from '@fluentui/react-icons';
import { SideNavigationAccordionProps } from './types';
// eslint-disable-next-line import/no-cycle
import { SideNavigationItem } from './side-navigation-item';
import { useSiteNavigationItemAccordionStyles } from './styles';

export const SideNavigationAccordion : React.FC<SideNavigationAccordionProps> = ({
  item,
  isCollapsed = false,
  secondaryNavItems,
  className,
  mode,
  config,
  onTabSelect,
  selectedTabId,
  groupId,
}) => {
  const classes = useSiteNavigationItemAccordionStyles();
  const [open, setOpen] = React.useState(!isCollapsed);
  const onClick = () => {
    setOpen(!open);
    item.onClick?.();
  };

  const Icon = open === false ? ChevronRight20Regular : ChevronDown20Regular;

  return (
    <div key={`tab_accordion_${item.id}`}>
      <OverflowItem
        key={`overflow_${item.id}`}
        id={item.id}
        groupId={groupId}
      >
        <Button icon={<Icon />} appearance="subtle" onClick={onClick} className={mergeClasses(classes.button, 'side-navigation-item-accordion')}>
          {item.text}
        </Button>
      </OverflowItem>
      {
        open
        && secondaryNavItems
          .map((secondaryItem) => (
            <SideNavigationItem
              key={`tab_accordion_item_${secondaryItem.id}`}
              item={secondaryItem}
              mode={mode}
              className={className}
              config={config}
              groupId={groupId}
              selectedTabId={selectedTabId}
              onTabSelect={onTabSelect}
            />
          ))
      }
    </div>
  );
};
