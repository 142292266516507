import { makeResetStyles, mergeClasses } from '@griffel/react';
export const breadcrumbClassNames = {
    root: 'fui-Breadcrumb',
    list: 'fui-Breadcrumb__list'
};
const useListClassName = makeResetStyles({
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
});
/**
 * Apply styling to the Breadcrumb slots based on the state
 */ export const useBreadcrumbStyles_unstable = (state)=>{
    const listBaseClassName = useListClassName();
    state.root.className = mergeClasses(breadcrumbClassNames.root, state.root.className);
    if (state.list) {
        state.list.className = mergeClasses(listBaseClassName, breadcrumbClassNames.list, state.list.className);
    }
    return state;
};
