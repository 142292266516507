import React, { forwardRef } from 'react';
import { Tab, makeStyles } from '@fluentui/react-components';
import type { PopoverTriggerChildProps } from '@fluentui/react-components';
import { SideNavigationItemProps, SideNavigationConfigProps } from './types';
import { SideNavigationModes } from './side-navigation-mode';
import { defaultConfig, getTabClassNames } from './utilities';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export type SideNavigationTabItemProps = {
  item: SideNavigationItemProps,
  mode: SideNavigationModes,
  className: string,
  config: SideNavigationConfigProps,
} & Partial<PopoverTriggerChildProps>;

export const SideNavigationItemTab = forwardRef<HTMLButtonElement, SideNavigationTabItemProps>((props, ref) => {
  const {
    item,
    config,
    mode,
    className,
  } = props;
  const {
    icon: Icon,
    id,
    text,
    onClick,
  } = item;

  const {
    isTemporarySelected = defaultConfig.isTemporarySelected, getAccessoryItem = defaultConfig.getAccessoryItem,
  } = config;

  const temporarySelected = isTemporarySelected(item);
  const styles = useStyles();
  const content = mode === SideNavigationModes.IconOnly
    ? undefined
    : <span className="side-navigation-item-text">{text}</span>;
  const resolvedIcon = mode === SideNavigationModes.TextOnly || !Icon ? undefined : <Icon />;
  const classes = getTabClassNames(item, temporarySelected, className);
  const accessoryItem = getAccessoryItem(item);

  return (
    <div key={`div_tab_${id}`} className={accessoryItem ? styles.container : undefined}>
      <Tab
        onClick={onClick}
        {...props}
        ref={ref}
        key={`tab_${id}`}
        value={id}
        className={classes}
        icon={resolvedIcon}
        role="tab"
        aria-label={text}
      >
        {content}
      </Tab>
      {
        accessoryItem
      }
    </div>
  );
});
