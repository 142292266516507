import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const caption1StrongerClassNames = {
    root: 'fui-Caption1Stronger'
};
/**
 * Styles for the root slot
 */ export const useCaption1StrongerStyles = makeStyles({
    root: typographyStyles.caption1Stronger
});
